const Subscribe = () => {
    return (
      <>
        <div className="container p-0">
          <h2 className='text-center p-5'>Subscribe our Newsletter and be up to date.</h2>
            <div className="box-subscribe mb-5 pb-3">
                <p className='text-center text-light m-0 p-3'>Subscribe and never miss a news.</p>
                <div className="row p-4 m-0">
                    <div className="col-9">
                        <input type='email' className="sub-tf" placeholder="Enter Your Email."></input>
                    </div>
                    <div className="col-3">
                        <button className="btn sub-btn">Subscribe</button>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
  };
  
  export default Subscribe;
  