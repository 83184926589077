import office from '../../img/Office.jpg'
import { motion } from "framer-motion";

const Hero = () => {

    return (
      <div className='hr'>
        <span></span>
        <img className='heroimg'  src={office} alt="4digitech office" />
        <div className='container'>
          {/* Enlarge On scroll */}
          <div className='herotext'>
          <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} /> 
            <h1>4digitech </h1>
            <h2>Your Partner in Digital Transformation</h2>
          </div>
        </div>
      </div>
    );
  };
  
  export default Hero;
  