import logo from '../../img/4dtrev.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const Footer = () => {

    return (
      <div className='footer-bg'>
        <span></span>
        <div class="container">
        <footer class="py-5">
            <div class="row">
                 <div class="col-md-3 col-sm-12 mb-3">
                    <img src={logo} alt="4digitech logo" className='footerlogo' />
                    <h5 className="py-2">Your partner in digital transformation.</h5>
                    <div className='social-icon'>
                        <FacebookIcon fontSize='large'></FacebookIcon>
                        <LinkedInIcon fontSize='large'></LinkedInIcon>
                        <InstagramIcon fontSize='large'></InstagramIcon>
                        <WhatsAppIcon fontSize='large'></WhatsAppIcon>
                    </div>
                </div>
                <div class="col-md-3 col-sm-12 mb-3">
                    <h5>Our Services</h5>
                    <ul class="nav flex-column">
                    <li class="nav-item mb-2"><a href="#" class="nav-link fw-normal p-0">Web Design</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link fw-normal p-0">Web Development</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link fw-normal p-0">Mobile App</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link fw-normal p-0">Social Media Marketing</a></li>
                    </ul>
                </div>

                <div class="col-md-3 col-sm-12 mb-3">
                    <h5>Company</h5>
                    <ul class="nav flex-column">
                    <li class="nav-item mb-2"><a href="#" class="nav-link fw-normal p-0">About</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link fw-normal p-0">Services</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link fw-normal p-0">Porfolio</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link fw-normal p-0">Contact Us</a></li>
                    </ul>
                </div>

                <div class="col-md-3 col-sm-12 mb-3">
                    <h5>Carrer</h5>
                    <ul class="nav flex-column">
                    <li class="nav-item mb-2"><a href="#" class="nav-link fw-normal p-0">Carrer at 4digitech</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link fw-normal p-0">Internship</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link fw-normal p-0">Drop Your CV</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link fw-normal p-0">FAQs</a></li>
                    <li class="nav-item mb-2"><a href="#" class="nav-link fw-normal p-0">About</a></li>
                    </ul>
                </div>
            </div>

            <div class="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                <p>© 2022 Company, Inc. All rights reserved.</p>
                <ul class="list-unstyled d-flex">
                    <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24"></svg></a></li>
                    <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24"></svg></a></li>
                    <li class="ms-3"><a class="link-dark" href="#"><svg class="bi" width="24" height="24"></svg></a></li>
                </ul>
            </div>
        </footer>
        </div>
      </div>
    );
  };
  
  export default Footer;
  