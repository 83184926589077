// import wave from '../../img/wavee.svg'
// import contact from '../../img/contact.png'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Map from '../../img/map.jpg';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FmdGoodTwoToneIcon from '@mui/icons-material/FmdGoodTwoTone';

const tf ={
  marginBottom:'20px',
  width:'100%',
};

const btn={
  width:'100%',
  background:'#092166',
}

const ContactUs = () => {
    return (
      <div id='contact'>
        <div className='contact-section'>
          {/* <img src={wave} alt="wave" className='wave'/> */}
            <div className='row p-0 m-0'>
              <div className='col-md-4 col-sm-12' style={{paddingLeft:"10%",paddingTop:"90px"}}>
                <h2 className='py-3 pr-0 primary'>4DigiTech</h2>
                <p className='pb-2 m-0 primary'>Office Headquater</p>
                <p className='fw-normal p-0 m-0'>Maitidevi, Kathmandu</p>
                <p className='fw-normal p-0 m-0'>Nepal</p>
                <p className='fw-normal pb-3 m-0'>44602</p>

                <p className='pb-2 m-0 primary'>info@4digitech.com</p>
                <div className='' style={{display:'flex'}}>
                  <h2 className='p-0 m-0 primary'>+977 </h2><h2> 9748275984</h2>
                </div>
                <p className='fw-normal p-0 m-0'>Sunday - Friday</p>
                <p className='fw-normal p-0 m-0'>10:00AM - 5PM</p>
                <hr></hr>
                <div className='social-icon'>
                  <FacebookIcon fontSize='large'></FacebookIcon>
                  <LinkedInIcon fontSize='large'></LinkedInIcon>
                  <InstagramIcon fontSize='large'></InstagramIcon>
                  <WhatsAppIcon fontSize='large'></WhatsAppIcon>
                </div>

                  {/* <img src={contact} alt='wave' className='contact-img'/> */}
              </div>
              <div className='col-md-4 col-sm-12 p-0'>
                <div className='box my-6'>
                  <h2 className='primary p-2'>Contact Us</h2>
                  <TextField label="Your Name" id="name" sx={tf}/>
                  <TextField label="Your Email" id="email"  sx={tf}/>
                  <TextField label="Your Phone" id="Phone"  sx={tf}/>
                  <TextField
                    id="outlined-multiline-static"
                    label="Your Question?"
                    multiline
                    rows={4} sx={tf}
                  />
                  <Button variant="contained" sx={btn}>Contact Us</Button>
                </div>
              </div>
              <div className='col-md-4 col-sm-12 p-0'>
              <div style={{width: "100%", height: "100%", position:"relative"}}>
                <div className='map-overlay'></div>
                <img src={Map} alt="wave" className='map-img'/>
                <button className='btn show-direction'><FmdGoodTwoToneIcon fontSize='large'></FmdGoodTwoToneIcon></button>
                {/* <iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=27.706743723155952,%2085.3334527559853+(4digitech)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">area maps</a></iframe> */}
              </div>
              </div>
            </div>
        </div>
      </div>
    );
  };
  
  export default ContactUs;
  