import planning from '../../img/p_planning.svg';
import designing from '../../img/p_designing.svg';
import development from '../../img/p_development.svg';
import deployment from '../../img/p_deployment.svg';
const HowWeWork = () => {

    return (
      <div id='HowWeWork'>
        <div className="container p-5">
          <h2 className='text-center pb-4'>How we work?</h2>
          <p  className='text-center'>Efficiency, excellence, and collaboration: our approach to getting the job done.</p>
          <div className="row p-0 my-5 card-bg">
            <div className="col-lg-3 col-md-6 col-sm-12 flex-column p-5">
              <div className="svgimg">
                <img src={planning} alt="planning"/>
              </div>
              <div className="text">
                <h5>Planning</h5>
                <p className='fw-normal'>We Plan Beforee Plane Plan</p>
              </div>        
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 flex-column p-5">
              <div className="svgimg">
                <img src={designing} alt="planning"/>
              </div>
              <div className="text">
                <h5>Designing</h5>
                <p className='fw-normal'>We Plan Beforee Plane Plan</p>
              </div>   
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 flex-column p-5">
              <div className="svgimg">
                <img src={development} alt="planning"/>
              </div>
              <div className="text">
                <h5>Developing</h5>
                <p className='fw-normal'>We Plan Beforee Plane Plan</p>
              </div>  
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 flex-column p-5">
              <div className="svgimg">
                <img src={deployment} alt="planning"/>
              </div>
              <div className="text">
                <h5>Deploying</h5>
                <p className='fw-normal'>We Plan Beforee Plane Plan</p>
              </div> 
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default HowWeWork;
  