import {Link} from 'react-router-dom';
import team from '../../img/team.png';
import pattern from '../../img/pattern.png';
const About = () => {
    return (
      <div id='about'>
        <img src={pattern} alt='pattern' className='pattern'/> 
        <div className='container p-5'>
          <div className='row p-0 m-0'>
            <div className='col-lg-6 p-0'>
                <div className='banner-text'>
                  <h3>About Us</h3>
                  <p className='headline'>A small creative team of artists and developers contributing to a better world. </p>
                  <p className='fw-normal-headline py-4'>At 4DigiTech, we are firm believers in the use of technology to boost the business. We are a group of committed professional youths who are passionate about guiding our clients through the constantly changing information technology world.</p>
                  <Link to='/about' className='btn btn-outline p-2'>Learn More</Link>
                </div>
            </div>
            <div className='col-lg-6 p-0'>
                <div className='img-right'>
                  <img src={team} alt="team"/>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default About;
  