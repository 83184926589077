import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import About from './pages/about';
import Services from './pages/services';
import Home from './pages';
import './App.css';
import './bootstrap.min.css';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Services />} />
      </Routes>
    </Router>
  );
}

export default App;
