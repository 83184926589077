import { Link } from "react-router-dom";
import web from '../../img/web-design.png';
import app from '../../img/app-dev.png';
import social from '../../img/social-media.png';
import coding from '../../img/coding.png';
const Services = () => {

    return (
      <div id="services">
        <div className="container p-5">
          <h2 className="text-center pb-4">Our Services</h2>
          <p className="text-center">Empowering your success through tailored and innovative solutions.</p>
          <div className="row p-0 my-5">
            <div className="col-lg-3 col-md-6 col-sm-12 p-0 fd-column">
            <div className="card-bg p-4 m-2">
              <img src={web} alt="planning" width={"100px"} className="pb-3"/>
              <h5 className="text-center">Web Designing</h5>
              <p className="fw-normal">We create and design websites that are visually appealing and easy to navigate for users.</p>
              <Link className="btn btn-outline" to='/services'>learn more</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 f p-0 fd-column">
              <div className="card-bg p-4 m-2">
                <img src={coding} alt="planning" width={"100px"} className="pb-3"/>
                <h5 className="text-center">Web Development</h5>
                <p className="fw-normal">We create and develop functional and dynamic websites that meets user requirement.</p>
                <Link className="btn btn-outline" to='/services'>learn more</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 p-0 fd-column">
            <div className="card-bg p-4 m-2">
              <img src={app} alt="planning" width={"100px"} className="pb-3"/>
              <h5 className="text-center">App Development</h5>
              <p className="fw-normal">We also create and develop mobile application that is portable, flexible and scalable.</p>
              <Link className="btn btn-outline" to='/services'>learn more</Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 p-0 fd-column">
            <div className="card-bg p-4 m-2">
              <img src={social} alt="planning" width={"100px"} className="pb-3"/>
              <h5 className="text-center">Social Media Marketing</h5>
              <p className="fw-normal">We Provide Social Media Marketing plans to promote your product, service or brand.</p>
              <Link className="btn btn-outline" to='/services'>learn more</Link>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default Services;
  